
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import { Route } from 'vue-router';
    import { Form as ElForm, Input } from 'element-ui';
    import { Dictionary } from 'vue-router/types/router'
    import API_Login from '@/utils/API/login/Login';
    import {MyBase64} from '@/utils/MyBase64';
    import _config from '@/config/config';

    @Component({
        components: {
        },
    })
    export default class Login extends Vue {
        private loginForm = {
            username: '',
            password: '',
            verify: '',
        };
        private loginRules = {
            username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
            password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            verify: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        };
        private passwordType = 'password';
        private loading = false;
        private verifyserver = _config.BASE_URL + "/core/api/v1/auth/captcha/get/black";
        private redirect?: string;
        private otherQuery: Dictionary<string> = {};

        @Watch('$route', { immediate: true })
        private onRouteChange(route: Route) {
            const query = route.query as Dictionary<string>
            if (query) {
                this.redirect = query.redirect;
                this.otherQuery = this.getOtherQuery(query);
            }
        }

        mounted() {
            if (this.loginForm.username === '') {
                (this.$refs.username as Input).focus();
            } else if (this.loginForm.password === '') {
                (this.$refs.password as Input).focus();
            }
        }

        private showPwd() {
            if (this.passwordType === 'password') {
                this.passwordType = '';
            } else {
                this.passwordType = 'password';
            }
            this.$nextTick(() => {
                (this.$refs.password as Input).focus();
            })
        }

        private async handleLogin() {
            (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
                if (valid) {
                    this.loading = true;
                    let form = {
                        username: this.loginForm.username,
                        password: MyBase64.Encode(this.loginForm.password),
                        verifycode: this.loginForm.verify
                    };

                    try {
                        const res: any = await API_Login.Login(form);
                        this.loading = false;
                        localStorage.setItem('qymanagementtoken', res.token);
                        localStorage.setItem('qymanagementusername', res.userName);

                        this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                    } catch {
                        this.loading = false;
                    }
                }
            });
        }

        private getOtherQuery(query: Dictionary<string>) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur]
                }
                return acc
            }, {} as Dictionary<string>)
        }

        private refreshVerifyCode() {
            this.verifyserver = _config.BASE_URL + "/core/api/v1/auth/captcha/get/black" + "?" + Math.random()
        }

    }
