import Request from "../Request";

export default class Login {
    public static Login(data: any) {
        return Request({
            url: `/core/api/v1/auth/admin/login1`,
            method: "post",
            loading: false,
            withCredentials: true,
            data
        });
    }

}